import { gsap } from "gsap";

// import { Fancybox } from "@fancyapps/ui";
// import "@fancyapps/ui/dist/fancybox/fancybox.css";

// window.addEventListener('load', ()=> {
//     Fancybox.bind('.fancybox-video', {
//         type: "iframe",
//     });
// });

window.addEventListener('load', () => {
    cardsAnimate();
});

const block = document.querySelector('.hero-block');

function cardsAnimate() {
    const cards = block.querySelectorAll('.cards-wrapper .card');
    const cardFirst = cards[0];
    const cardLast = cards[cards.length - 1];

    const cardSecond = cards[cards.length - 3];
    const cardPrev = cards[cards.length - 2];

    // It's calc Angle
    const lastAngel = 50 / cardLast.clientWidth * 68;
    // It's width to move first element
    const secondWidth = cardSecond.clientWidth / 100;

    // Moved element from left
    gsap.from(cardFirst, {
        opacity: 0,
        x: '-300px',
        duration: 1.5,
        ease: 'none',
        // After complete first animation we do next
        onComplete: ()=>{
            gsap.to(cardFirst, {
                duration: 2,
                x: secondWidth * 93,
            })
            gsap.to(cardSecond, {
                duration: 2,
                x: secondWidth * 103,
            })
            gsap.to(cardSecond, {
                y: 10,
                duration: 1.7,
                delay: 0.3,
            })
            gsap.to(cardSecond, {
                rotateZ: '-20deg',
                duration: 0.5,
                delay: 0.1,
                ease: 'none',
                onComplete: ()=>{
                    gsap.to(cardSecond, {
                        rotateZ: '-45deg',
                        duration: 1.6,
                        // ease: 'none',
                        // ease: "power1.inOut",
                        ease: "power1.out",
                    }) 
                },
            })
            gsap.to(cardSecond, {
                rotateZ: '-45deg',
                duration: 1.4,
                delay: 0.65,
            })
            gsap.to(cardPrev, {
                duration: 2,
                x: "50px",
            })
            gsap.to(cardLast, {
                duration: 2,
                rotateZ: lastAngel + 'deg',
            })
        },
    });
}
